import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {
  recognition: any;
  isListening = false;
  public isVoiceSupported = true;

  constructor(private zone: NgZone) {
    const SpeechRecognition =
      (window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      this.isVoiceSupported = false;
      return;
    }

    this.recognition = new SpeechRecognition();
    this.recognition.continuous = false;
    this.recognition.lang = 'en-US';
    this.recognition.interimResults = false;

    this.recognition.onend = () => {};

    this.recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error);
    };
  }

  startListening(callback: (text: string) => void) {
    if (!this.isVoiceSupported) {
      return;
    }
    this.isListening = true;
    this.recognition.start();

    this.recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      this.zone.run(() => callback(transcript));
      this.stopListening();
    };
  }

  stopListening() {
    if (!this.isVoiceSupported) {
      return;
    }
    if (this.isListening) {
      this.isListening = false;
      this.recognition.stop();
    }
  }
}
